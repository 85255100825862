import React, { FC } from 'react'

import { Box, Center, Text } from '@chakra-ui/react'
import { CircleIcon } from '../icons'

interface Props {
    color: string
    bg: string
    text: string
    addText: string
    addTextColor: string
    handleClick: () => void
}

export const CheuronMid: FC<Props> = ({
    color,
    bg,
    text,
    addText,
    addTextColor,
    handleClick,
}) => {
    return (
        <Box w="full">
            <Box display="flex" w="full" h="28px">
                <Box
                    borderTop={`12px solid ${bg}`}
                    borderBottom={`12px solid ${bg}`}
                    borderRight={`12px solid ${bg}`}
                    borderLeft={`6px solid transparent`}
                />
                <Box
                    bg={bg}
                    w="full"
                    display="flex"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={handleClick}
                    alignItems="center"
                    textAlign="center"
                    pr={3}>
                    <Text color={color}>{text}</Text>
                </Box>
                <Box
                    borderTop={`12px solid transparent`}
                    borderBottom={`12px solid transparent`}
                    borderLeft={`6px solid ${bg}`}
                />
            </Box>
            {addText && (
                <Center mt={1}>
                    <CircleIcon transform="scale(0.70)" color={addTextColor} />
                    <Text fontSize={8} fontWeight={400} color="#474747">
                        {addText}
                    </Text>
                </Center>
            )}
        </Box>
    )
}
